import { MetaData } from '../meta-data';
import Footer from '../../components/footer';
import { PlacesSearch } from '../places-search';
import { useTranslation } from 'next-i18next';
import { useDefaultLocationsPage } from '../../hooks';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { SearchHeader } from '../../components/search-header';
import { useInitializeConductrics } from '../../hooks/use-initialize-conductrics';
import { LocationsByAttributes } from './hotel-locations/locations-by-attributes';
import { LocationsByRegion } from './hotel-locations/locations-by-region';
import { TopMarketLocations } from './locations-destination-reels/top-market-locations';
import { TopPromoLocations } from './locations-destination-reels/top-promo-locations';
import { DestinationGuides } from './destination-guides/destination-guides';
import { AboutLocationsPage } from '../about-locations/about-locations-page';

export const DefaultLocationsPage = () => {
  const { t } = useTranslation(['default-locations-page', 'footer']);
  const { guestInfo, isLoading: isAuthLoading, isAuthenticated } = useAuth();

  // conductrics init
  useInitializeConductrics({ authLoading: isAuthLoading, guestInfo, isAuthenticated });
  const { aboutContentBlock, pageInterLinks, isGeoCodePageLoading } = useDefaultLocationsPage();

  return (
    <div className="flex flex-col">
      <MetaData
        brandCode="WW"
        description={t('default-locations-page:pageDescription')}
        pageTitle={t('default-locations-page:pageTitle')}
        path="/locations"
      />
      <SearchHeader isFluid />
      <main role="main">
        <PlacesSearch ctaText={t('default-locations-page:findHotel')} />
        <div className="text-primary flex items-center p-4 text-2xl font-black leading-snug sm:text-4xl lg:pl-10">
          <h2>{t('default-locations-page:destinationsYouWillLove')}</h2>
        </div>
        <div className="container px-4 lg:px-8">
          <TopMarketLocations />
          <TopPromoLocations />
          <DestinationGuides />
        </div>
        <LocationsByRegion />
        <LocationsByAttributes isLoading={isGeoCodePageLoading} pageInterLinks={pageInterLinks} />
        <AboutLocationsPage
          about={aboutContentBlock}
          aboutClassName="px-4 py-3 lg:px-8"
          contentBlockClassName="py-2 px-2"
          descriptionClassName="p-0"
        />
      </main>
      <Footer isFluid />
    </div>
  );
};
