import { useTranslation } from 'next-i18next';
import { useGetTopPromoPlaces } from './hook/use-get-top-promo-places';
import { useIsClient } from 'usehooks-ts';
import { DynamicGridWOM } from '@dx-ui/osc-dynamic-grids';
import { getTopPromoDynamicGridItems } from './reel-utils';
import { ReelSkeleton } from './reel-skeleton';

export const TopPromoLocations = () => {
  const { t } = useTranslation(['default-locations-page']);
  const isClient = useIsClient();

  const { isLoading, topPromoPlaces } = useGetTopPromoPlaces();

  if (!isClient || isLoading) {
    return <ReelSkeleton id="topPromoLocationsLoading" />;
  }

  if (!topPromoPlaces || topPromoPlaces.length === 0) return null;
  const topPromoPlacesDynamicGridItems = getTopPromoDynamicGridItems(topPromoPlaces, t);

  return (
    <>
      <div
        className="flex items-center pb-2.5 pt-0 text-lg font-bold leading-snug sm:text-xl lg:pl-2"
        data-testid="top-promo-loc-heading"
      >
        <h3>{t('default-locations-page:hiltonsTopPick')}</h3>
      </div>
      <div className="pb-4" data-testid="topPromoLocations">
        <DynamicGridWOM
          id="top-promo-places"
          items={topPromoPlacesDynamicGridItems}
          imageAspectRatio="3:2"
          wrapperClassName="p-0 ml-2"
        />
      </div>
    </>
  );
};
