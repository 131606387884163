import { TabList, TabListButton, TabPanel, TabPanels, Tabs } from '@dx-ui/osc-tabs';
import { HalfAndHalf } from '@dx-ui/osc-half-and-half';
import { useIsClient } from 'usehooks-ts';

import { useTranslation } from 'react-i18next';
import { DESTINATION_GUIDES_DATA } from './constants/destination-guides-data';
import { prefixPublicAssetUriWithAppPrefix } from '../../../utils';

export const DestinationGuides = () => {
  const isClient = useIsClient();
  const destinationGuides = DESTINATION_GUIDES_DATA.items;
  const { t } = useTranslation('default-locations-page');

  if (!isClient) return null;
  return (
    <div>
      <div
        className="text-primary flex items-center py-4 text-2xl font-black leading-snug sm:text-4xl"
        data-testid="destination-guides-heading"
      >
        <h2>{t('destinationsToDiscover')}</h2>
      </div>
      <Tabs defaultActive="1">
        <TabList className="border-border-alt border-b">
          {destinationGuides?.map((destinationGuide) => (
            <TabListButton
              id={destinationGuide.id}
              key={destinationGuide.tab.label}
              className="hover:text-primary focus:text-primary grow cursor-pointer appearance-none whitespace-nowrap px-4 py-2 text-base font-bold md:whitespace-normal md:text-2xl"
              activeClassName="border-b-primary text-primary border-b-4"
              inactiveClassName="text-text-alt"
            >
              {t(`destinationGuides.${destinationGuide.tab.label}`)}
            </TabListButton>
          ))}
        </TabList>
        <TabPanels>
          {destinationGuides?.map((destinationGuide) => (
            <TabPanel id={destinationGuide.id} key={destinationGuide.tab.label} className="py-2">
              <HalfAndHalf
                headline={t(`destinationGuides.${destinationGuide.panel.headline}`)}
                headlineClassName="md:text-4xl"
                description={t(`destinationGuides.${destinationGuide.panel.copy}`)}
                descriptionClassName="md:text-xl"
                imageUrl={prefixPublicAssetUriWithAppPrefix(
                  `/assets/destination-guides/${destinationGuide.panel.image.url}`
                )}
                hasYLayoutInversed={true}
                primaryCtaButton={{
                  adaDescription: t(`destinationGuides.${destinationGuide.panel.link.label}`),
                  label: t(`destinationGuides.${destinationGuide.panel.link.label}`),
                  url: destinationGuide.panel.link.url,
                }}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};
