/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect } from 'react';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import getBreakpoint from '../../../utils/get-breakpoint';

type ReelSkeletonProps = {
  id?: string;
};

export const ReelSkeleton = ({ id }: ReelSkeletonProps) => {
  const isMobile = isBrowser && window.innerWidth < (getBreakpoint('sm', true) as number);
  const isTablet =
    isBrowser &&
    window.innerWidth < (getBreakpoint('lg', true) as number) &&
    window.innerWidth > (getBreakpoint('sm', true) as number);

  const [showLoadingItems, setShowLoadingItems] = useState(5);

  useEffect(() => {
    setShowLoadingItems(isMobile ? 1 : isTablet ? 2 : 4);
  }, [isMobile, isTablet]);

  let cnt = 0;
  return (
    <div data-testid={id}>
      <div className="flex items-center pt-4">
        <div className="bg-bg-alt h-7 w-56 max-w-xs animate-pulse" />
      </div>
      <div className="flex h-64 overflow-x-scroll border-b pb-6">
        <div className="mx-auto my-0 mt-6 flex h-full max-w-screen-xl animate-pulse flex-nowrap pb-2">
          {Array.from({ length: showLoadingItems }, () => (
            <div className="bg-bg-alt mx-4 h-full w-96 rounded p-6" key={cnt++} />
          ))}
        </div>
      </div>
    </div>
  );
};
