import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { RegionTabs } from './region-tabs';
import type { LinkItem } from './alphabetized-items';
import { AlphabetizedLinkItems } from './alphabetized-items';
import { useMediaQuery } from 'usehooks-ts';
import { useGetRegions, useWrappedRouter } from '../../../hooks';
import getBreakpoint from '../../../utils/get-breakpoint';
import { publicRuntimeConfig } from '../../../constants';
import cx from 'classnames';
import { Accordion } from '@dx-ui/osc-accordion';
import { AccordionSubPanel } from '../accordion/accordion-sub-panel';

export const LocationsByRegion = () => {
  const { t } = useTranslation('default-locations-page');
  const { router } = useWrappedRouter();
  const isDesktop = useMediaQuery(`(min-width:${getBreakpoint('lg')}px)`);
  const isTablet = useMediaQuery(`(min-width:${getBreakpoint('md')}px)`);

  const { isLoading, regions: regionList } = useGetRegions({});

  const [regionTabIndex, setRegionTabIndex] = useState(0);
  const [initialRender, setInitialRender] = useState(true);

  if (isLoading) return null;

  const handleRegionTabChange = (regionTabIndex: number) => {
    setRegionTabIndex(regionTabIndex);
    if (initialRender) setInitialRender(false);
  };

  const hostAndlang = `${publicRuntimeConfig.OHW_BASE_URL}${router.locale}`;

  const nonNACountries = regionTabIndex > 0 ? regionList?.[regionTabIndex]?.countries : [];
  return (
    <div>
      <div className="bg-border-alt flex items-center px-4 pt-4 lg:px-10">
        <div className="border-border bg-text-inverse w-full border">
          <Accordion
            className="accordion-stacked px-2 pl-4"
            collapsedButtonLabel={t('regionAccordionTitle')}
            expandedButtonLabel={t('regionAccordionTitle')}
            shouldBeInitiallyExpanded={true}
            variant="stacked"
          >
            <section>
              <RegionTabs
                linkNameTag={t('searchText')}
                regions={
                  regionList?.map((region) => ({
                    name: region?.name || '',
                    uri: `${hostAndlang}${region?.locationPageUri}` || '',
                  })) as LinkItem[]
                }
                setRegionTabIndex={(regionTabIndex) => handleRegionTabChange(regionTabIndex)}
              >
                {regionTabIndex > 0 ? (
                  <AlphabetizedLinkItems
                    columnCount={isDesktop ? 5 : isTablet ? 3 : 2}
                    linkItems={
                      nonNACountries?.map((country) => ({
                        name: country.displayName,
                        uri: `${hostAndlang}${country.locationPageUri}`,
                      })) as LinkItem[]
                    }
                  />
                ) : (
                  regionList?.map((region, regionIndex) =>
                    region?.countries.map((country, index) => {
                      const statesOrCities =
                        country?.states?.length > 0 ? country?.states : country?.cities || [];
                      return (
                        <div
                          className={cx('pb-0.5', { hidden: regionIndex !== regionTabIndex })}
                          key={`region-${region.name}-country-${country.name}`}
                        >
                          {statesOrCities?.length ? (
                            <AccordionSubPanel
                              isExpanded={regionTabIndex === 0 && index === 0}
                              key={`region-${region.name}-country-${country.name}`}
                              linkItem={{
                                name: country.displayName,
                                uri: `${hostAndlang}${country.locationPageUri}` || '',
                              }}
                              linkNameTag={t('searchText')}
                            >
                              <AlphabetizedLinkItems
                                columnCount={isDesktop ? 5 : isTablet ? 3 : 2}
                                itemTitle={country.displayName}
                                key={`region-${region.name}-country-${country.name}-items`}
                                linkItems={statesOrCities?.map((stateOrCity) => ({
                                  name: stateOrCity.name,
                                  uri: `${hostAndlang}${stateOrCity.locationPageUri}`,
                                }))}
                              />
                            </AccordionSubPanel>
                          ) : (
                            <noscript>
                              <div>
                                <a
                                  className="text-primary flex pl-4"
                                  data-testid={`location-link-region${regionIndex}-country${index}`}
                                  href={`${hostAndlang}${country.locationPageUri}` || ''}
                                >
                                  {country.displayName}
                                </a>
                              </div>
                            </noscript>
                          )}
                        </div>
                      );
                    })
                  )
                )}
              </RegionTabs>
            </section>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
