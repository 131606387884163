/* eslint-disable require-await */
import { DefaultLocationsPage } from '../../components/default-locations-page';
import { TransactionViewWrapper } from '../../components/view-transactions/transactions-view-wrapper';
import type { GetServerSidePropsContext } from 'next';
import {
  serverSideGeocodePageQuery,
  serverSideAllRegionsQuery,
  serverSideFeatureToggles_FeatureConfigsQuery,
} from '../../gql/queries';
import { AppProvider } from '../../providers/app-provider';
import { serverSideProps, getCustomHeaders } from '../../utils/serverSideProps';
import {
  COMMON_NAMESPACES,
  LOCATIONS_NAMESPACES,
  REACT_QUERY_CONFIG,
  serverRuntimeConfig,
} from '../../constants';
import { getServerSideClients } from '@dx-ui/framework-react-query';

export default function DefaultLocations({ isOneLink }: { isOneLink: boolean }) {
  return (
    <AppProvider isOneLink={isOneLink}>
      <DefaultLocationsPage />
      <TransactionViewWrapper />
    </AppProvider>
  );
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { res, locale, resolvedUrl: url } = context;
  const customHeaders = getCustomHeaders(context);
  const { queryClient } = getServerSideClients({
    ...REACT_QUERY_CONFIG,
    customHeaders,
    response: res,
  });
  const translateAutocompleteConfig = await serverSideFeatureToggles_FeatureConfigsQuery(
    queryClient,
    {
      names: ['config-ui-translate-autocomplete'],
    }
  ).catch((e) => {
    console.log(`Error in serverSideFeatureToggles_FeatureConfigsQuery: ${e}`); // eslint-disable-line no-console
    return null;
  });
  const configLanguages = translateAutocompleteConfig?.featureConfigs?.[0]?.config?.languages;
  const contentLanguage = url
    ? new URL(serverRuntimeConfig.OHW_BASE_URL + url)?.searchParams.get('content')
    : undefined;
  const language = contentLanguage ?? locale;
  const isSupportedLanguage = !!configLanguages?.length && configLanguages.includes(language);

  return serverSideProps({
    context,
    namespaces: [...COMMON_NAMESPACES, ...LOCATIONS_NAMESPACES],
    getServerSideQueries({ queryClient }) {
      return [
        serverSideAllRegionsQuery(queryClient, { language: 'en' }).catch((e) => {
          console.log(`Error in serverSideAllRegionsQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
        serverSideGeocodePageQuery(queryClient, {
          language: isSupportedLanguage && language ? language : 'en',
          pageType: 'DEFAULT',
          path: '/locations',
        }).catch((e) => {
          console.log(`Error in serverSideGeocodePageQuery: ${e}`); // eslint-disable-line no-console
          return null;
        }),
      ];
    },
  });
};
