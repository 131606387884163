import { useRef } from 'react';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import type { HalfAndHalfButtonProps } from './half-and-half-button';
import { HalfAndHalfButton } from './half-and-half-button';
import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';
import type { Maybe } from '@dx-ui/gql-types';

export type HalfAndHalfProps = {
  /**
   * optional full-bleed background color that will overide the reverse bg color
   */
  bgColor?: string;
  /**
   * should the half and half horizontal layout be flipped?
   */
  hasXLayoutInversed?: boolean;
  /**
   * should the half and half vertical layout for mobile be flipped?
   */
  hasYLayoutInversed?: boolean;
  /**
   * the url to the image.
   */
  imageUrl?: Maybe<string>;
  /**
   * renders the headline as an heading.
   */
  headline?: string;
  /**
   * customize headline style.
   */
  headlineClassName?: string;
  /**
   * renders the description as a paragraph.
   */
  description?: string | null;
  /**
   * customize description style.
   */
  descriptionClassName?: string;
  /**
   * the description of the image being rendered.
   */
  imageAltText?: Maybe<string>;
  /**
   * primary call to action button.
   */
  primaryCtaButton?: HalfAndHalfButtonProps;
  /**
   * secondary call to action button.
   */
  secondaryCtaButton?: HalfAndHalfButtonProps;
  /**
   * renders the component with a parallax animation.
   */
  hasParallax?: boolean;
  /**
   * the speed of Parallax
   */
  speed?: number;
};

/**
 * HalfAndHalf is an in-page component that renders items with alternating alignments and bg colors.
 *
 * By default, the reverse layout will alternate the background color. This can be overwritten by utilizing the optional `bgColor` prop. In order to use parallax, wrap the half-and-half component with the `<ParallaxProvider/>` from `react-scroll-parallax` npm package.
 *
 * The horizontal layout for mobile by default is text content then image. This  can be reversed brining the image up to the top, then the text by using the inverseXLayout prop.
 *
 * Markdown can be passed into the description as part of the string to allow for flexible content.
 *
 * The primary and secondary cta buttons by default have brand coloring by default and can be overwritten by passing in custom class names. In order to trigger a function or modal, within an optional `onClick` prop is available.
 */
export const HalfAndHalf = ({
  bgColor,
  hasXLayoutInversed = false,
  hasYLayoutInversed = false,
  hasParallax = false,
  imageUrl,
  headline,
  headlineClassName,
  description,
  descriptionClassName,
  imageAltText,
  primaryCtaButton,
  secondaryCtaButton,
  speed = -10,
}: HalfAndHalfProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const aspectRatio: AspectRatio = '3:2';
  const isMobile = (rect?.width ?? 0) < 1024;

  return (
    <div
      className={cx(
        'w-full bg-bg',
        {
          'bg-bg-alt': hasXLayoutInversed && !bgColor,
        },
        bgColor
      )}
      data-testid="half-and-half-bg-wrapper"
    >
      <div
        ref={ref}
        className={cx('flex flex-col lg:flex-row justify-center overflow-hidden pt-1', {
          'lg:flex-row-reverse': !hasXLayoutInversed,
          'flex-col-reverse': hasYLayoutInversed,
        })}
        data-testid="half-and-half-content-wrapper"
      >
        <div
          className={cx('flex flex-1 items-center justify-start', {
            'lg:ml-12': !!imageUrl && !hasXLayoutInversed,
            'lg:mr-12 ': !!imageUrl && hasXLayoutInversed,
            'w-full lg:w-auto justify-center': !imageUrl,
            'w-full': !!imageUrl,
          })}
        >
          <div className="md:mb-2 md:pt-2">
            {headline ? (
              <h3
                className={cx(
                  'font-headline text-primary mb-2 mt-6 text-2xl lg:mb-4 lg:text-3xl',
                  headlineClassName
                )}
              >
                {headline}
              </h3>
            ) : null}

            {description ? (
              <div className={cx('py-4 font-sans text-base lg:pb-9 lg:pt-0', descriptionClassName)}>
                <CustomMarkdown>{description}</CustomMarkdown>
              </div>
            ) : null}

            <div className="flex gap-4">
              {primaryCtaButton ? <HalfAndHalfButton {...primaryCtaButton} /> : null}
              {secondaryCtaButton ? (
                <HalfAndHalfButton {...secondaryCtaButton} isSecondary />
              ) : null}
            </div>
          </div>
        </div>

        {imageUrl && !hasParallax ? (
          <div ref={ref} className="flex-1 overflow-hidden">
            <ResponsiveImage
              aspectRatio={aspectRatio}
              imageUrl={imageUrl}
              altText={imageAltText ?? ''}
              width={rect?.width ?? 0}
            />
          </div>
        ) : null}

        {imageUrl && hasParallax ? (
          <div ref={ref} className="flex-1 overflow-hidden" data-testid="parallax-wrapped-image">
            <ParallaxBanner className="aspect-[3/2]" disabled={isMobile}>
              <ParallaxBannerLayer
                speed={speed}
                expanded={false}
                scale={[1.2, 1.2]}
                disabled={isMobile}
              >
                <ResponsiveImage
                  aspectRatio={aspectRatio}
                  imageUrl={imageUrl}
                  altText={imageAltText ?? ''}
                  width={rect?.width ?? 0}
                />
              </ParallaxBannerLayer>
            </ParallaxBanner>
          </div>
        ) : null}
      </div>
    </div>
  );
};
