import { useEffect } from 'react';
import { useMetrics } from '../../../hooks';
import { useIsClient } from 'usehooks-ts';
import { useTranslation } from 'next-i18next';
import { useGetTopMarketPlaces } from './hook/use-get-top-market-places';
import { DynamicGridWOM } from '@dx-ui/osc-dynamic-grids';
import { getTopMarketDynamicGridItems } from './reel-utils';
import { ReelSkeleton } from './reel-skeleton';

export const TopMarketLocations = () => {
  const { t } = useTranslation(['default-locations-page']);
  const metrics = useMetrics();
  const isClient = useIsClient();

  const { isLoading, topMarketPlaces } = useGetTopMarketPlaces();

  // featured Locations analytics
  useEffect(() => {
    if (!isLoading && topMarketPlaces) {
      metrics.setFeaturedLocationData({
        featuredLocationsList:
          topMarketPlaces?.map((topMarketPlace) => topMarketPlace?.links[0]?.linkLabel).join('|') ||
          '',
      });
    }
  }, [topMarketPlaces, isLoading, metrics]);

  if (!isClient || isLoading) {
    return <ReelSkeleton id="topMarketLocationsLoading" />;
  }

  if (!topMarketPlaces || topMarketPlaces?.length === 0) return null;
  const topMarketPlacesDynamicGridItems = getTopMarketDynamicGridItems(topMarketPlaces, t);

  return (
    <>
      <div
        className="flex items-center pb-2.5 text-lg font-bold leading-snug sm:text-xl lg:pl-2"
        data-testid="top-market-loc-heading"
      >
        <h3>{t('recommendedForYou')}</h3>
      </div>
      <div data-testid="topMarketLocations">
        <DynamicGridWOM
          id="top-market-places"
          items={topMarketPlacesDynamicGridItems}
          imageAspectRatio="3:2"
          wrapperClassName="p-0 ml-2"
        />
      </div>
    </>
  );
};
