import { Link } from '@dx-ui/osc-link';
import type { Link as LinkType } from '@dx-ui/osc-link';
import cx from 'classnames';

export type HalfAndHalfButtonProps = Omit<LinkType, 'url'> & {
  /**
   * optional classes for CTA button
   */
  className?: string;
  /**
   * changes styling for a secondary CTA button
   */
  isSecondary?: boolean;
  /**
   * trigger for an onClick button event
   */
  onClick?: () => void;
  /**
   * Set the `href` attribute for the link
   */
  url?: string;
};

export const HalfAndHalfButton = ({
  className,
  adaDescription,
  isNewWindow = false,
  isSecondary = false,
  label,
  onClick,
  showNewWindowIcon = false,
  underline = false,
  url,
}: HalfAndHalfButtonProps) => {
  function handleOnClick() {
    if (onClick) onClick();
  }

  if (url && label && adaDescription) {
    return (
      <Link
        adaDescription={adaDescription}
        className={cx(
          {
            'btn btn-primary btn-xl lg:mt-0 lg:px-12': !className && !isSecondary,
            'btn btn-primary-outline btn-xl lg:mt-0 lg:px-12': !className && isSecondary,
            underline: !!underline,
          },
          className
        )}
        isNewWindow={isNewWindow}
        onClick={handleOnClick}
        showNewWindowIcon={showNewWindowIcon}
        underline={underline}
        url={url}
      >
        {label}
      </Link>
    );
  }

  if (label && adaDescription)
    return (
      <button
        aria-label={adaDescription}
        type="button"
        className={cx(
          {
            'btn btn-primary btn-xl mt-2 lg:mt-0 lg:px-12 mb-4': !className && !isSecondary,
            'btn btn-primary-outline btn-xl mt-2 lg:mt-0 lg:px-12 mb-4': !className && isSecondary,
            underline: !!underline,
          },
          className
        )}
        onClick={handleOnClick}
      >
        {label}
      </button>
    );

  return null;
};
