export const DESTINATION_GUIDES_DATA = {
  items: [
    {
      id: '1',
      panel: {
        copy: 'texas.copy',
        headline: 'texas.headline',
        image: {
          alt: 'texas.headline',
          url: 'state_texas.jpg',
        },
        link: {
          adaDescription: 'texas.label',
          label: 'texas.linkLabel',
          url: 'https://www.hilton.com/en/destination-guides/texas/',
        },
      },
      tab: {
        label: 'texas.label',
      },
    },
    {
      id: '2',
      panel: {
        copy: 'florida.copy',
        headline: 'florida.headline',
        image: {
          alt: 'florida.headline',
          url: 'state_florida.jpg',
        },
        link: {
          adaDescription: 'florida.linkLabel',
          label: 'florida.linkLabel',
          url: 'https://www.hilton.com/en/destination-guides/florida/',
        },
      },
      tab: {
        label: 'florida.label',
      },
    },
    {
      id: '3',
      panel: {
        copy: 'california.copy',
        headline: 'california.headline',
        image: {
          alt: 'california.headline',
          url: 'state_california.png',
        },
        link: {
          adaDescription: 'california.linkLabel',
          label: 'california.linkLabel',
          url: 'https://www.hilton.com/en/destination-guides/california/',
        },
      },
      tab: {
        label: 'california.label',
      },
    },
  ],
} as const;
